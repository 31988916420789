// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   kicker -> text
//   headline -> text / Überschrift
//   headline_level -> option / Überschriftengröße
//   subheadline -> text / Unterzeile
//   text -> markdown
// end of automatic

import React from "react"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import TextReveal from "../reveals/TextReveal"
import BlockWrapper from "../technical/BlockWrapper"

const TextBlock = ({ block }) => {
  return (
    <BlockWrapper
      block={block}
      blockWidth={
        block.flow ? "container" : block.align_left ? "content-left" : "content"
      }
      showHeadline="true"
      showButtons="true"
      className=""
      blockPadding="py-8 md:py-16"
    >
      <div className={block.headline?.length > 0 ? "pt-8" : ""}>
        <TextReveal>
          <Richtext
            flow={block.flow}
            text={block.text}
            className={block.text_size === "text-lg" ? block.text_size : ""}
          />
        </TextReveal>
      </div>
    </BlockWrapper>
  )
}

export default TextBlock
